/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering */

export class OfflineConversationControllerApi {
    protected basePath = 'https://callcenter.naumen.ru';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

    /**
     * 
     * @summary offlineFields
     * @param showcaseId showcaseId
     * @param crmId crmId
     */
    public offlineFields (showcaseId: number, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatOfflineFieldsResponse> {
        const localVarPath = this.basePath + '/visitor/offline/fields/{showcaseId}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling offlineFields.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary sendOffline
     * @param showcaseId showcaseId
     * @param chatStartOfflineRequest 
     * @param crmId crmId
     */
    public sendOffline (showcaseId: number, chatStartOfflineRequest: models.ChatStartOfflineRequest, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/offline/start/{showcaseId}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling sendOffline.');
        }

        // verify required parameter 'chatStartOfflineRequest' is not null or undefined
        if (chatStartOfflineRequest === null || chatStartOfflineRequest === undefined) {
            throw new Error('Required parameter chatStartOfflineRequest was null or undefined when calling sendOffline.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatStartOfflineRequest,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary toOffline
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param chatStartOfflineRequest 
     * @param crmId crmId
     */
    public toOffline (showcaseId: number, conversationId: number, chatStartOfflineRequest: models.ChatStartOfflineRequest, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/offline/update/{showcaseId}/{conversationId}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling toOffline.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling toOffline.');
        }

        // verify required parameter 'chatStartOfflineRequest' is not null or undefined
        if (chatStartOfflineRequest === null || chatStartOfflineRequest === undefined) {
            throw new Error('Required parameter chatStartOfflineRequest was null or undefined when calling toOffline.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatStartOfflineRequest,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
}
