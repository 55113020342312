/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering */

export class WelcomeControllerApi {
    protected basePath = 'https://callcenter.naumen.ru';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

    /**
     * 
     * @summary authorizeUser
     * @param showcaseId showcaseId
     * @param requestBody authParams
     * @param crmId crmId
     */
    public authorizeUser (showcaseId: number, requestBody: { [key: string]: string; }, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatAuthorizeUserResponse> {
        const localVarPath = this.basePath + '/visitor/welcome/{showcaseId}/authorize/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling authorizeUser.');
        }

        // verify required parameter 'requestBody' is not null or undefined
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling authorizeUser.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: requestBody,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary csrf
     */
    public csrf (extraHttpRequestParams?: any ) : ng.IHttpPromise<string> {
        const localVarPath = this.basePath + '/visitor/welcome/csrf/**';

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Загрузка существующего conversation\'a
     * @param showcaseId showcaseId
     * @param requestBody body
     */
    public getExistingConversation (showcaseId: number, requestBody: { [key: string]: string; }, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatExistingConversationResponse> {
        const localVarPath = this.basePath + '/visitor/welcome/{showcaseId}/existing/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getExistingConversation.');
        }

        // verify required parameter 'requestBody' is not null or undefined
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling getExistingConversation.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: requestBody,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Получение витрины после ее обновления
     * @param showcaseId showcaseId
     */
    public getShowcase (showcaseId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatShowcase> {
        const localVarPath = this.basePath + '/visitor/welcome/{showcaseId}/showcase'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getShowcase.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary involveData
     * @param showcaseId showcaseId
     * @param crmId crmId
     */
    public involveData (showcaseId: number, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<Array<models.ChatInvolveScenarioWrapper>> {
        const localVarPath = this.basePath + '/visitor/involve/{showcaseId}/data'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling involveData.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary involveShow
     * @param showcaseId showcaseId
     * @param chatInvolveShowRequest 
     */
    public involveShow (showcaseId: number, chatInvolveShowRequest?: models.ChatInvolveShowRequest, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/involve/{showcaseId}/show'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling involveShow.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatInvolveShowRequest,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Присоединение текущего визитора к кастомеру
     * @param showcaseId showcaseId
     * @param chatLinkRequest 
     */
    public linkVisitorAndCustomer (showcaseId: number, chatLinkRequest?: models.ChatLinkRequest, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatLinkResponse> {
        const localVarPath = this.basePath + '/visitor/welcome/{showcaseId}/merge/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling linkVisitorAndCustomer.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatLinkRequest,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary setAttributes
     * @param showcaseId showcaseId
     * @param requestBody attributes
     * @param crmId crmId
     */
    public setAttributes (showcaseId: number, requestBody: { [key: string]: string; }, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatSetAttributesResponse> {
        const localVarPath = this.basePath + '/visitor/welcome/{showcaseId}/setattributes/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling setAttributes.');
        }

        // verify required parameter 'requestBody' is not null or undefined
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling setAttributes.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: requestBody,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary welcome
     * @param showcaseId showcaseId
     * @param requestBody body
     * @param crmId crmId
     */
    public welcome (showcaseId: number, requestBody: { [key: string]: string; }, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatWelcomeResponse> {
        const localVarPath = this.basePath + '/visitor/welcome/{showcaseId}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling welcome.');
        }

        // verify required parameter 'requestBody' is not null or undefined
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling welcome.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: requestBody,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary welcomeData
     * @param showcaseId showcaseId
     * @param crmId crmId
     * @param location location
     */
    public welcomeData (showcaseId: number, crmId?: string, location?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatWelcomeDataResponse> {
        const localVarPath = this.basePath + '/visitor/welcome/{showcaseId}/data'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling welcomeData.');
        }

        if (location !== undefined) {
            queryParameters['location'] = location;
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
}
