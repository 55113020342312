/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering */

export class TypingControllerApi {
    protected basePath = 'https://callcenter.naumen.ru';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

    /**
     * 
     * @summary Для уведомления о том, что абонент печатает
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     */
    public startVisitorTyping (showcaseId: number, conversationId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<{}> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/typing/visitor'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling startVisitorTyping.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling startVisitorTyping.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
}
