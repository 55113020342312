/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ChatAttachment {
    "autoRemoveDate"?: number;
    "conversationId"?: number;
    "ownerId"?: number;
    "filename"?: string;
    "id"?: number;
    "length"?: number;
    "md5"?: string;
    "mimeType"?: string;
    "regDate"?: number;
    "type"?: ChatAttachment.ChatTypeEnum;
}

export namespace ChatAttachment {
    export enum ChatTypeEnum {
        ACCOUNTAVATAR = <any> 'ACCOUNT_AVATAR',
        USERATTACH = <any> 'USER_ATTACH',
        OPERATORATTACH = <any> 'OPERATOR_ATTACH',
        USERAVATAR = <any> 'USER_AVATAR',
        TEMPORARYFILE = <any> 'TEMPORARY_FILE'
    }
}
