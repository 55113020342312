export * from './AsrControllerApi';
import { AsrControllerApi } from './AsrControllerApi';
export * from './CallControllerApi';
import { CallControllerApi } from './CallControllerApi';
export * from './DialogControllerApi';
import { DialogControllerApi } from './DialogControllerApi';
export * from './OfflineConversationControllerApi';
import { OfflineConversationControllerApi } from './OfflineConversationControllerApi';
export * from './OnlineConversationControllerApi';
import { OnlineConversationControllerApi } from './OnlineConversationControllerApi';
export * from './TypingControllerApi';
import { TypingControllerApi } from './TypingControllerApi';
export * from './VisitorResourceControllerApi';
import { VisitorResourceControllerApi } from './VisitorResourceControllerApi';
export * from './WelcomeControllerApi';
import { WelcomeControllerApi } from './WelcomeControllerApi';
export const APIS = [AsrControllerApi, CallControllerApi, DialogControllerApi, OfflineConversationControllerApi, OnlineConversationControllerApi, TypingControllerApi, VisitorResourceControllerApi, WelcomeControllerApi];
