/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering */

export class DialogControllerApi {
    protected basePath = 'https://callcenter.naumen.ru';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

    /**
     * 
     * @summary Можно ли сейчас отправлять сообщение
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     */
    public canTypeMessage (showcaseId: number, conversationId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/cantype/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling canTypeMessage.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling canTypeMessage.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отправить событие клика по кнопке с ссылкой
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param chatClickEvent 
     */
    public clickButton (showcaseId: number, conversationId: number, chatClickEvent?: models.ChatClickEvent, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/clickButton/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling clickButton.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling clickButton.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatClickEvent,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Количество непрочитанных сообщений от оператора
     * @param showcaseId Идентификатор витрины
     */
    public countUnread (showcaseId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatCountResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/countUnread/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling countUnread.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Завершить заполнение опросника (отправить результаты во внешнюю систему) после submitQuestionary
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     */
    public finishQuestionary (showcaseId: number, conversationId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/questionary/finish/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling finishQuestionary.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling finishQuestionary.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Список событий диалога
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param lastEventTimestamp События позже этой даты
     */
    public getDialogEvents (showcaseId: number, conversationId: number, lastEventTimestamp?: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<Array<models.ChatDialogEvent>> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getDialogEvents.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling getDialogEvents.');
        }

        if (lastEventTimestamp !== undefined) {
            queryParameters['lastEventTimestamp'] = lastEventTimestamp;
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Список событий диалога с мета-данными аккаунтов и файлов
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param lastEventTimestamp События позже этой даты
     */
    public getDialogEventsWithData (showcaseId: number, conversationId: number, lastEventTimestamp?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatDialogEventsWithData> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/data/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getDialogEventsWithData.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling getDialogEventsWithData.');
        }

        if (lastEventTimestamp !== undefined) {
            queryParameters['lastEventTimestamp'] = lastEventTimestamp;
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Диалог из истории
     * @param showcaseId Идентификатор витрины
     * @param page Номер диалога в истории. 0 - предыдущий (или текущий, если есть)
     */
    public getHistoryConversation (showcaseId: number, page: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatConversation> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/history/{page}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'page' + '}', encodeURIComponent(String(page)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getHistoryConversation.');
        }

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getHistoryConversation.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Опросник для витрины
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     */
    public getQuestionary (showcaseId: number, conversationId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatConversationQuestionary> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/questionary/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getQuestionary.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling getQuestionary.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Автодополнение для текста пользовательского сообщения без создания обращения
     * @param showcaseId Идентификатор витрины
     * @param textToComplete Сообщение пользователя
     */
    public getSuggestionWithoutConversation (showcaseId: number, textToComplete?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/getSuggestion/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getSuggestionWithoutConversation.');
        }

        if (textToComplete !== undefined) {
            queryParameters['textToComplete'] = textToComplete;
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отметить сообщение как доставленное
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param eventId Идентификатор сообщения
     */
    public markAsDelivered (showcaseId: number, conversationId: number, eventId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/{eventId}/markDelivered/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)))
            .replace('{' + 'eventId' + '}', encodeURIComponent(String(eventId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling markAsDelivered.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling markAsDelivered.');
        }

        // verify required parameter 'eventId' is not null or undefined
        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling markAsDelivered.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отметить сообщение как прочитанное
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param eventId Идентификатор сообщения
     */
    public markAsRead (showcaseId: number, conversationId: number, eventId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/{eventId}/markRead/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)))
            .replace('{' + 'eventId' + '}', encodeURIComponent(String(eventId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling markAsRead.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling markAsRead.');
        }

        // verify required parameter 'eventId' is not null or undefined
        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling markAsRead.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Новое вложение
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param chatNewAttachmentModel Вложение
     */
    public newAttachment (showcaseId: number, conversationId: number, chatNewAttachmentModel: models.ChatNewAttachmentModel, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/attachment/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling newAttachment.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling newAttachment.');
        }

        // verify required parameter 'chatNewAttachmentModel' is not null or undefined
        if (chatNewAttachmentModel === null || chatNewAttachmentModel === undefined) {
            throw new Error('Required parameter chatNewAttachmentModel was null or undefined when calling newAttachment.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatNewAttachmentModel,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Новое сообщение
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param chatNewMessageModel Сообщение
     */
    public newMessage (showcaseId: number, conversationId: number, chatNewMessageModel?: models.ChatNewMessageModel, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/message/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling newMessage.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling newMessage.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatNewMessageModel,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Голосовое сообщение
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param chatNewVoiceMessageModel Вложение
     */
    public newVoiceMessage (showcaseId: number, conversationId: number, chatNewVoiceMessageModel: models.ChatNewVoiceMessageModel, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/voicemessage/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling newVoiceMessage.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling newVoiceMessage.');
        }

        // verify required parameter 'chatNewVoiceMessageModel' is not null or undefined
        if (chatNewVoiceMessageModel === null || chatNewVoiceMessageModel === undefined) {
            throw new Error('Required parameter chatNewVoiceMessageModel was null or undefined when calling newVoiceMessage.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatNewVoiceMessageModel,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Предложить оценку со стороны пользователя
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     */
    public offerRateResponse (showcaseId: number, conversationId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/offer-rate/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling offerRateResponse.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling offerRateResponse.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Оценить диалог
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param chatRateRequest Оценка
     */
    public rate (showcaseId: number, conversationId: number, chatRateRequest?: models.ChatRateRequest, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/rate/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling rate.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling rate.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatRateRequest,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Завершить диалог
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     */
    public resolveDialog (showcaseId: number, conversationId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatResolveResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/resolve/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling resolveDialog.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling resolveDialog.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отправить историю сообщений на почту
     * @param showcaseId Идентификатор витрины
     * @param chatSendHistoryToEmailRequest 
     */
    public sendHistoryToEmail (showcaseId: number, chatSendHistoryToEmailRequest?: models.ChatSendHistoryToEmailRequest, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/sendHistoryToEmail/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling sendHistoryToEmail.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatSendHistoryToEmailRequest,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Заполнить опросник или его часть. После полного заполнения надо вызвать finishQuestionary
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param chatSubmitQuestionnaireData Ответы на вопросы опросника
     */
    public submitQuestionary (showcaseId: number, conversationId: number, chatSubmitQuestionnaireData?: models.ChatSubmitQuestionnaireData, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/questionary/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling submitQuestionary.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling submitQuestionary.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatSubmitQuestionnaireData,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Автодополнение для текста пользовательского сообщения
     * @param showcaseId Идентификатор витрины
     * @param conversationId Идентификатор диалога
     * @param textToComplete Сообщение пользователя
     */
    public suggest (showcaseId: number, conversationId: number, textToComplete?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/suggest/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling suggest.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling suggest.');
        }

        if (textToComplete !== undefined) {
            queryParameters['textToComplete'] = textToComplete;
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
}
