/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ChatVisitorWebError {
    "field"?: string;
    "message": string;
    "exception"?: string;
    "code"?: ChatVisitorWebError.ChatCodeEnum;
}

export namespace ChatVisitorWebError {
    export enum ChatCodeEnum {
        CONVERSATIONEXISTS = <any> 'CONVERSATION_EXISTS',
        SHOWCASEBLOCKED = <any> 'SHOWCASE_BLOCKED',
        VOICEMESSAGE = <any> 'VOICE_MESSAGE',
        LEVITANRECOGNITION = <any> 'LEVITAN_RECOGNITION'
    }
}
