/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ChatLinkResponse {
    "result": ChatLinkResponse.ChatResultEnum;
}

export namespace ChatLinkResponse {
    export enum ChatResultEnum {
        Ok = <any> 'ok',
        Fail = <any> 'fail'
    }
}
