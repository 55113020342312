/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering */

export class CallControllerApi {
    protected basePath = 'https://callcenter.naumen.ru';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

    /**
     * 
     * @summary Остановка WebRtc-сессии
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     */
    public reject (showcaseId: number, conversationId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<{}> {
        const localVarPath = this.basePath + '/visitor/{showcaseId}/online/dialog/{conversationId}/call/reject'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling reject.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling reject.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отправка кандидата WebRtc-сессии
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param body webrtc sdp candidate
     */
    public sendCandidate (showcaseId: number, conversationId: number, body: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<{}> {
        const localVarPath = this.basePath + '/visitor/{showcaseId}/online/dialog/{conversationId}/call/candidate'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling sendCandidate.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling sendCandidate.');
        }

        // verify required parameter 'body' is not null or undefined
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling sendCandidate.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: body,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отправка запроса на создание WebRtc-сессии
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param body webrtc sdp offer
     */
    public sendOffer (showcaseId: number, conversationId: number, body: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<{}> {
        const localVarPath = this.basePath + '/visitor/{showcaseId}/online/dialog/{conversationId}/call/offer'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling sendOffer.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling sendOffer.');
        }

        // verify required parameter 'body' is not null or undefined
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling sendOffer.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: body,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отправка события о включении/выключении камеры
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param body camera is on or off
     */
    public toggleCamera (showcaseId: number, conversationId: number, body: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<{}> {
        const localVarPath = this.basePath + '/visitor/{showcaseId}/online/dialog/{conversationId}/call/camera'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling toggleCamera.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling toggleCamera.');
        }

        // verify required parameter 'body' is not null or undefined
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling toggleCamera.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: body,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Отправка события о включении/выключении микрофона
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param body microphone is on or off
     */
    public toggleMicrophone (showcaseId: number, conversationId: number, body: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<{}> {
        const localVarPath = this.basePath + '/visitor/{showcaseId}/online/dialog/{conversationId}/call/microphone'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling toggleMicrophone.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling toggleMicrophone.');
        }

        // verify required parameter 'body' is not null or undefined
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling toggleMicrophone.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: body,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
}
