import * as $ from 'jquery';
import ChatClient from '../../client';
import { ChatInvolveScenarioWrapper, ChatInvolveScenarioSiteSettings } from '../chat-api-v1';
import { ContextWatcher } from './ContextWatcher';

/**
 * Created by semyon on 30.08.16.
 */
const store = require('store')

export class InvolvementService {
    private chatClient: ChatClient;
    private contextWatcher: ContextWatcher;
    private scenarios: ChatInvolveScenarioWrapper[] = [];
    private checkScenarionIntervalId: number;

    constructor(chatClient: ChatClient, contextWatcher: ContextWatcher) {
        this.chatClient = chatClient;
        this.contextWatcher = contextWatcher;
        this.loadInvolveScenario(chatClient.showcaseId, this.onInvolveScenarioLoaded.bind(this), () => {});

        const preserveSession = document.cookie.split(';').filter((val) => {
                return val.indexOf("INVOLVE_SESSION_MONITOR") != -1;
            }).length > 0;

        if (!preserveSession) {
            this.onSessionClear();
            document.cookie = "INVOLVE_SESSION_MONITOR=1; path=/; SameSite=None; Secure";
        }
    }

    loadInvolveScenario(showcaseId, successCallback, errorCallback) {
        $.getJSON(this.chatClient.host + "/visitor/involve/" + showcaseId + "/data").done(successCallback).fail(errorCallback);
    }

    onInvolveScenarioLoaded(data: ChatInvolveScenarioWrapper[]) {
        this.scenarios = this.filterScenarios(data);
        this.startScenarioPolling();
    }

    startScenarioPolling(): void {
        this.stopScenarioPolling();
        this.checkScenarionIntervalId = setInterval(this.checkScenarios.bind(this), 7000);//раз в 7 секунд
    }

    stopScenarioPolling() {
        if (this.checkScenarionIntervalId) {
            clearInterval(this.checkScenarionIntervalId);
        }
    }

    filterScenarios(scenarios: ChatInvolveScenarioWrapper[]) {
        const filteredScenarios: ChatInvolveScenarioWrapper[] = [];

        function dayOfYear(date: Date) {
            const start = new Date(date.getFullYear(), 0, 0);
            const diff = date.getTime() - start.getTime();
            const oneDay = 1000 * 60 * 60 * 24;
            return Math.floor(diff / oneDay);
        }

        const data = store.get("scenarios-data") || {};

        for (let i = 0; i < scenarios.length; i++) {
            const obj = scenarios[i];
            const scenario = obj.scenario;

            const dailyLimit = scenario.showDailyLimitRestrictions;
            const sessionLimit = scenario.showSessionLimitRestrictions;

            // достанем историю использования involve-сценария
            const usageHistory = data[scenario.id];

            if (usageHistory) {
                // проверим, что в этой сессии ещё не превысили количество показов
                if (usageHistory.usedInSession >= sessionLimit) {
                    continue;
                }

                const lastShownDate = new Date(usageHistory.day);
                // проверим, что показы были сегодня
                if (dayOfYear(new Date()) == dayOfYear(lastShownDate)) {
                    // проверим, что не превысили кол-во показов за день
                    if (usageHistory.usedInDay >= dailyLimit) {
                        continue;
                    }
                } else {
                    // данные не для сегодняшнего дня, их можно удалить
                    // в дату кладём millis
                    usageHistory.day = new Date().getTime();
                    usageHistory.usedInDay = 0;
                }
            }

            filteredScenarios.push(obj);
        }

        store.set("scenarios-data", data);

        return filteredScenarios;
    }

    /**
     * Остановка применения сценариев вовлечения
     */
    stop() {
        this.stopScenarioPolling();
    }

    /**
     * Применить сценарий вовлечения
     * @param scenario сценарий вовлечения
     */
    useInvolveScenario(scenario) {
        this.stopScenarioPolling();

        const data = store.get("scenarios-data") || {};
        let scenarioData = data[scenario.scenario.id];
        if (!scenarioData) {
            scenarioData = {
                usedInSession: 0,
                usedInDay: 0
            };
            data[scenario.scenario.id] = scenarioData;
        }
        scenarioData.day = new Date().getTime();
        scenarioData.usedInSession++;
        scenarioData.usedInDay++;

        store.set("scenarios-data", data);

        this.chatClient.openChat(scenario.siteSettings);
    }

    /**
     *
     */
    onSessionClear() {
        this.contextWatcher.resetContext();
        const data = store.get("scenarios-data") || {};
        const scenarioIds = Object.keys(data);
        for (let i = 0; i < scenarioIds.length; i++) {
            const scenarioId = scenarioIds[i];
            data[scenarioId].usedInSession = 0;
        }
        store.set("scenarios-data", data);
    }

    onNewPage() {
        this.checkScenarios();
    }

    checkScenarios() {
        const thisPageId = this.chatClient.pageId;

        const sessionContext = this.contextWatcher.getContext();

        // длина сессии в секундах
        const sessionLengthSeconds = (new Date().getTime() - sessionContext.startTime) / 1000;
        const sessionDepth = (sessionContext.history ? sessionContext.history.length : 0);

        const filtered = this.scenarios.filter(function (obj) {
            const scenario = obj.scenario;
            const siteSettings = obj.siteSettings;

            // проверим, что сценарий доступен для данной страницы
            const pageId = siteSettings.pageId;
            if (pageId == thisPageId) {
                // page id правильный
                if (siteSettings.type !== ChatInvolveScenarioSiteSettings.ChatTypeEnum.IN) {
                    // разрешено для всех кроме этого
                    return false;
                }
            } else {
                // не тот page id
                if (siteSettings.type !== ChatInvolveScenarioSiteSettings.ChatTypeEnum.OUT) {
                    // разрешено только для page id из сценария
                    return false;
                }
            }

            const sessionDeepRestrictions = scenario.sessionDeepRestrictions;
            if (sessionDepth >= sessionDeepRestrictions || sessionDeepRestrictions === undefined) {
                const sessionDurationRestrictions = scenario.sessionDurationRestrictions;
                if (sessionLengthSeconds >= sessionDurationRestrictions || sessionDurationRestrictions === undefined) {
                    return true;
                }
            }

            return false;
        });

        if (filtered.length > 0) {
            this.useInvolveScenario(filtered[0]);
        }

    }

}

