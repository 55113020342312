/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ChatAttributeValue {
    "attributeId"?: number;
    "objectId"?: number;
    "scope"?: models.ChatAttributeScope;
    "source"?: ChatAttributeValue.ChatSourceEnum;
    "timestamp"?: number;
    "value"?: string;
}

export namespace ChatAttributeValue {
    export enum ChatSourceEnum {
        MANUALUSER = <any> 'MANUAL_USER',
        AUTO = <any> 'AUTO'
    }
}
