/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ChatShowcaseField {
    "id"?: number;
    "title"?: string;
    /**
     * Для какого выбранного навыка запрашивать данный атрибут. Используется для обратной совместимости с mobile SDK
     */
    "skillId"?: number;
    "showcaseId"?: number;
    "attributeId"?: number;
    "fieldOrder"?: number;
    "isRequired"?: boolean;
    "scope"?: ChatShowcaseField.ChatScopeEnum;
}

export namespace ChatShowcaseField {
    export enum ChatScopeEnum {
        ALL = <any> 'ALL',
        ONLINEONLY = <any> 'ONLINE_ONLY',
        OFFLINEONLY = <any> 'OFFLINE_ONLY'
    }
}
