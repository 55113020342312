/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering */

export class OnlineConversationControllerApi {
    protected basePath = 'https://callcenter.naumen.ru';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

    /**
     * 
     * @summary Информация о диалоге
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param crmId crmId
     */
    public getConversation (showcaseId: number, conversationId: number, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatConversationAndOperatorData> {
        const localVarPath = this.basePath + '/visitor/online/dialog/{showcaseId}/{conversationId}/info/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getConversation.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling getConversation.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Информация об операторе
     * @param operatorId operatorId
     */
    public getOperatorInfo (operatorId: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatOperatorShortData> {
        const localVarPath = this.basePath + '/visitor/online/operator/{operatorId}/**'
            .replace('{' + 'operatorId' + '}', encodeURIComponent(String(operatorId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'operatorId' is not null or undefined
        if (operatorId === null || operatorId === undefined) {
            throw new Error('Required parameter operatorId was null or undefined when calling getOperatorInfo.');
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Перевести обращение на оператора
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param crmId crmId
     */
    public rerouteToOperator (showcaseId: number, conversationId: number, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/route/{showcaseId}/{conversationId}/operator/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling rerouteToOperator.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling rerouteToOperator.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Создать обращение
     * @param showcaseId showcaseId
     * @param chatConversationStartRequest 
     * @param crmId crmId
     */
    public startConversation (showcaseId: number, chatConversationStartRequest: models.ChatConversationStartRequest, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatVisitorWebResponse> {
        const localVarPath = this.basePath + '/visitor/online/start/{showcaseId}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling startConversation.');
        }

        // verify required parameter 'chatConversationStartRequest' is not null or undefined
        if (chatConversationStartRequest === null || chatConversationStartRequest === undefined) {
            throw new Error('Required parameter chatConversationStartRequest was null or undefined when calling startConversation.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            data: chatConversationStartRequest,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
    /**
     * 
     * @summary Добавить обращение в очередь маршрутизации
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     * @param crmId crmId
     */
    public waitForRoute (showcaseId: number, conversationId: number, crmId?: string, extraHttpRequestParams?: any ) : ng.IHttpPromise<{ [key: string]: string; }> {
        const localVarPath = this.basePath + '/visitor/online/route/{showcaseId}/{conversationId}/**'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)))
            .replace('{' + 'conversationId' + '}', encodeURIComponent(String(conversationId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling waitForRoute.');
        }

        // verify required parameter 'conversationId' is not null or undefined
        if (conversationId === null || conversationId === undefined) {
            throw new Error('Required parameter conversationId was null or undefined when calling waitForRoute.');
        }

        headerParams['crmId'] = crmId;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
}
