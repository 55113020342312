/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ChatDialogEvent {
    "accountId"?: number;
    "attribute"?: models.ChatKeyValueEntry;
    "conversationId"?: number;
    "creationTimestamp"?: number;
    "deliverTimestamp"?: number;
    "direction"?: models.ChatDialogEventDirection;
    "fake"?: boolean;
    "id"?: number;
    "location"?: models.ChatLocation;
    "mode"?: models.ChatMode;
    "_new"?: boolean;
    "previousAccountId"?: number;
    "rateResponse"?: Array<models.ChatKeyValueEntry>;
    "rateView"?: models.ChatRateReqEventView;
    "readTimestamp"?: number;
    "reason"?: ChatDialogEvent.ChatReasonEnum;
    "reply"?: models.ChatReply;
    "showcaseId"?: number;
    "system"?: boolean;
    /**
     * дубликат поля system, используется для обратной совместимости с mobile SDK
     */
    "isSystem"?: boolean;
    "textLength"?: number;
    "textView"?: models.ChatDialogEventView;
    "type"?: models.ChatDialogEventType;
    /**
     * Используется для обратной совместимости с mobile SDK
     */
    "recommendToReroute"?: boolean;
}

export namespace ChatDialogEvent {
    export enum ChatReasonEnum {
        INITIAL = <any> 'INITIAL',
        BYUSER = <any> 'BY_USER',
        BYOPERATOR = <any> 'BY_OPERATOR',
        BYUSERTIMEOUT = <any> 'BY_USER_TIMEOUT',
        SETTINGSCHANGED = <any> 'SETTINGS_CHANGED',
        INTERNALSERVERERROR = <any> 'INTERNAL_SERVER_ERROR'
    }
}
