/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '../model/models';

/* tslint:disable:no-unused-variable member-ordering */

export class AsrControllerApi {
    protected basePath = 'https://callcenter.naumen.ru';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

    /**
     * 
     * @summary Получить настройки для распознавания в Левитане
     * @param showcaseId showcaseId
     * @param conversationId conversationId
     */
    public getAsrSettings (showcaseId: number, conversationId?: number, extraHttpRequestParams?: any ) : ng.IHttpPromise<models.ChatAsrSettings> {
        const localVarPath = this.basePath + '/visitor/{showcaseId}/asr/settings'
            .replace('{' + 'showcaseId' + '}', encodeURIComponent(String(showcaseId)));

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        // verify required parameter 'showcaseId' is not null or undefined
        if (showcaseId === null || showcaseId === undefined) {
            throw new Error('Required parameter showcaseId was null or undefined when calling getAsrSettings.');
        }

        if (conversationId !== undefined) {
            queryParameters['conversationId'] = conversationId;
        }

        let httpRequestParams: ng.IRequestConfig = {
            method: 'GET',
            url: localVarPath,
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }
}
