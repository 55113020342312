/**
 * Created by semyon on 23.08.16.
 */

const store = require('store')

type HistoryItem = {
    url: string;
    title: string;
    count: number;
}

export type ContextWatcherType = {
    startTime: number;
    accessTime: number;
    history: HistoryItem[];
}

export class ContextWatcher {
    private available: boolean;

    constructor() {
        this.available = true;
        if (!store.enabled) {
            this.available = false;
        }
    }

    public start(): void {
        if (!this.available) {
            return;
        }

        /**
         * TODO:
         * когда обнулять сессию?
         * возможно по куке, которая стирается с закрытием браузера?
         *
         */
        let startTime: number = store.get("start-time");
        if (!startTime) {
            // начало сессии
            startTime = new Date().getTime();
            store.set("start-time", startTime);
        }

        //для single page applications
        if ("onhashchange" in window) {
            window.onhashchange = function () {
                const newHash = location.hash;
                this.onNewPage();
            }.bind(this);
        }
        this.onNewPage();
    }

    private onNewPage(): void {
        const pageTitle = document.title;
        const pageUrl = location.hostname + location.pathname + location.hash;

        let history: HistoryItem[] = store.get("history");
        if (!history) {
            history = [];
        }

        const lastPage = history[history.length - 1];
        if (lastPage && lastPage.url == pageUrl) {
            lastPage.count++;
        } else {
            history.push({url: pageUrl, title: pageTitle, count: 1});
        }

        // храним не больше 30
        if (history.length > 30) {
            history.splice(0, 1);
        }

        // сохраняем объект истории
        store.set("history", history);
    }

    public getContext(): ContextWatcherType | null {
        if (!this.available) {
            return null;
        }
        const startTime: number = store.get("start-time");
        const now = new Date().getTime();
        const history: HistoryItem[] = store.get("history");
        return {
            startTime: startTime,
            accessTime: now,
            history: history
        };
    }

    public resetContext(): void {
        store.remove("start-time");
        store.remove("history");
        this.start();
    }

}
