/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ChatVisitorWebResponse {
    "result"?: object;
    "errors"?: Array<models.ChatVisitorWebError>;
    "status"?: ChatVisitorWebResponse.ChatStatusEnum;
}

export namespace ChatVisitorWebResponse {
    export enum ChatStatusEnum {
        OK = <any> 'OK',
        ERROR = <any> 'ERROR',
        NOTAUTHORIZED = <any> 'NOT_AUTHORIZED',
        ACCESSDENIED = <any> 'ACCESS_DENIED'
    }
}
