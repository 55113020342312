/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

/**
 * Сценарий вовлечения
 */
export interface ChatInvolveScenario {
    "id"?: number;
    "name": string;
    "deleted"?: boolean;
    /**
     * Выборка регионов, для которых действует сценарий. Если пуста, то действует для всех.
     */
    "regionRestrictions"?: Array<number>;
    /**
     * Кому показывать сценарий (по авторизованности) > * `ALL` - Всем * `AUTH` - Авторизованным * `UNAUTH` - Неавторизованным
     */
    "authRestriction": ChatInvolveScenario.ChatAuthRestrictionEnum;
    /**
     * Сколько страниц должен посетить пользователь, прежде чем ему будет показано окно. Если не заполнено - показываем при первом же заходе.
     */
    "sessionDeepRestrictions"?: number;
    /**
     * Время пребывания на сайте до показа окна, мгновенно, если не заполненно. Значение в секундах.
     */
    "sessionDurationRestrictions"?: number;
    /**
     * Текст вовлечения
     */
    "involveText": string;
    /**
     * Сколько раз будет показано окно за сутки. Если не указано, то сколько угодно.
     */
    "showDailyLimitRestrictions"?: number;
    /**
     * Сколько раз будет выскакивать окно. Если не указано, то сколько угодно.
     */
    "showSessionLimitRestrictions"?: number;
}

export namespace ChatInvolveScenario {
    export enum ChatAuthRestrictionEnum {
        ALL = <any> 'ALL',
        AUTH = <any> 'AUTH',
        UNAUTH = <any> 'UNAUTH'
    }
}
