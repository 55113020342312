/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

/**
 * Связка сценария вовлечения для сайта
 */
export interface ChatInvolveScenarioSiteSettings {
    "id": number;
    /**
     * Идентификатор страницы
     */
    "pageId"?: string;
    /**
     * Тип условия > * `IN` - Указанная страница * `OUT` - Кроме указанной страницы
     */
    "type"?: ChatInvolveScenarioSiteSettings.ChatTypeEnum;
}

export namespace ChatInvolveScenarioSiteSettings {
    export enum ChatTypeEnum {
        IN = <any> 'IN',
        OUT = <any> 'OUT'
    }
}
