/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from './models';

/**
 * Витрина чата
 */
export interface ChatShowcase {
    /**
     * идентификатор витрины
     */
    "id"?: number;
    /**
     * Текст при назначении на оператора. Используется для обратной совместимости с mobile SDK
     */
    "assignText"?: string;
    /**
     * Показывать Copyright
     */
    "showCopyright"?: boolean;
    /**
     * Передавать аватар и имя профиля. Используется для обратной совместимости с mobile SDK
     */
    "showOperatorInfo"?: boolean;
    /**
     * Опция отображения аватара и имени оператора > * `NOT_SHOWN` - не отображать * `DIALOG` - в диалоге * `HEADER`- в шапке * `HEADER_AND_DIALOG` - в шапке и диалоге * `HEADER_AND_DIALOG_OVERRIDES_STATIC_HEADER` - В шапке и диалоге (заменяя staticHeader)
     */
    "showOperatorInfoOption": ChatShowcase.ChatShowOperatorInfoOptionEnum;
    /**
     * Разрешить обмен файлами
     */
    "useAttaches"?: boolean;
    /**
     * Использовать автодополнение
     */
    "useAutocomplete"?: boolean;
    /**
     * Разрешить офлайн-заявки
     */
    "useOffline"?: boolean;
    /**
     * Использовать видеозвонки
     */
    "useVideo"?: boolean;
    /**
     * Принимать видеозвонки до начала диалога в чате
     */
    "startWithVideo"?: boolean;
    /**
     * Использовать кобраузинг
     */
    "useCobrowsing"?: boolean;
    "isOnline"?: boolean;
    /**
     * Блокировка витрины
     */
    "blocked"?: boolean;
    /**
     * Текст блокировки витрины
     */
    "blockedText"?: string;
    /**
     * Текст приветствия
     */
    "welcomeText"?: string;
    /**
     * Текст при ожидании ответа от оператора. Используется для обратной совместимости с mobile SDK
     */
    "agentHandlingText"?: string;
    /**
     * подтверждение готовности пройти опрос
     */
    "questionaryAcceptQuestion"?: string;
    /**
     * Текст с предложением оценить обращение
     */
    "rateText": string;
    /**
     * Текст после оценки первая строка
     */
    "afterRateTextHead"?: string;
    /**
     * Текст после оценки вторая строка (1-3 звезды)
     */
    "afterLowRateText"?: string;
    /**
     * Текст после оценки вторая строка (4-5 звезды)
     */
    "afterHighRateText"?: string;
    /**
     * Текст ссылки на опрос
     */
    "questionaryLinkText"?: string;
    /**
     * Текст при завершении обращения
     */
    "resolveText"?: string;
    /**
     * Текст при начале видеовызова
     */
    "webrtcAnswer"?: string;
    /**
     * Текст при завершении видеовызова
     */
    "webrtcReject"?: string;
    /**
     * Текст при неуспешной попытке видеовызова
     */
    "webrtcCancel"?: string;
    /**
     * Текст при начале кобраузинга
     */
    "cobrowsingAnswer"?: string;
    /**
     * Текст при завершении кобраузинга
     */
    "cobrowsingReject"?: string;
    /**
     * Текст при перенаправлении на другого оператора. Используется для обратной совместимости с mobile SDK
     */
    "routeText"?: string;
    "mode": models.ChatMode;
    /**
     * Текст с согласием на обработку персональных данных в формате HTML
     */
    "privacyPolicyHtml"?: string;
    /**
     * Приветствие в офлайн-форме
     */
    "offlineFormWelcomeText"?: string;
    /**
     * Подтверждение в офлайн-форме
     */
    "offlineFormSubmitText"?: string;
    /**
     * Текст ответа на офлайн-сообщение
     */
    "offlineAnswerText"?: string;
    /**
     * Текст при получении сообщения из офлайн-формы
     */
    "offlineFormReceiveText"?: string;
    /**
     * всегда доступна кнопка \"перевести на оператора\" при диалоге с роботом. Используется для обратной совместимости с mobile SDK
     */
    "routeToOperatorAlreadyAvailable"?: boolean;
    /**
     * Разрешить отправку истории на почту
     */
    "useSendHistory"?: boolean;
    "messagePlaceholder"?: string;
}

export namespace ChatShowcase {
    export enum ChatShowOperatorInfoOptionEnum {
        NOTSHOWN = <any> 'NOT_SHOWN',
        HEADER = <any> 'HEADER',
        DIALOG = <any> 'DIALOG',
        HEADERANDDIALOG = <any> 'HEADER_AND_DIALOG',
        HEADERANDDIALOGOVERRIDESSTATICHEADER = <any> 'HEADER_AND_DIALOG_OVERRIDES_STATIC_HEADER'
    }
}
